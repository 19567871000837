import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import ReceiptPage from './pages/ReceiptPage';
import Footer from './components/Footer';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import Login from './pages/Login';
import { auth } from './firebaseConfig';
import './App.css';
import TemplatePage from './components/TemplatePage';
import ApiDocumentation from './pages/ApiDocumentation';
import ReceiptTemplatePage from './pages/ReceipTemplatePage';
const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return unsubscribe;
  }, []);

  const handleLogout = async () => {
    await auth.signOut();
    localStorage.removeItem('apiKey');
    setUser(null);
  };

  const MainLayout = ({ children }) => (
    <>
      <Header user={user} onLogout={handleLogout} />
      <div className="content">{children}</div>
      <Footer />
    </>
  );

  const NoHeaderFooterLayout = ({ children }) => (
    <div className="content">{children}</div>
  );

  return (
    <div className="app-container">
      <Router>
        <Routes>
          <Route path="/" element={<MainLayout><Home /></MainLayout>} />
          <Route path="/dashboard" element={<MainLayout>{user ? <Dashboard /> : <Login />}</MainLayout>} />
          <Route path="/receipt-template/:id" element={<MainLayout><ReceiptPage /></MainLayout>} />
          <Route path="/api-docs" element={<MainLayout><ApiDocumentation /></MainLayout>} />
          <Route path="/contact" element={<MainLayout><div>Contact Us</div></MainLayout>} />
          <Route path="/TermsOfService" element={<MainLayout><TermsOfService /></MainLayout>} />
          <Route path="/PrivacyPolicy" element={<MainLayout><PrivacyPolicy /></MainLayout>} />
          <Route path="/login" element={<MainLayout><Login /></MainLayout>} />
          <Route path="/templates" element={<MainLayout><TemplatePage /></MainLayout>} />
          <Route path="/receipt/:id" element={<NoHeaderFooterLayout><ReceiptTemplatePage /></NoHeaderFooterLayout>} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
